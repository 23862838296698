<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { computed, ref, watch } from 'vue';
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import Button from 'primevue/button';
    import { useAuthenticationStore } from '@/stores/authentication.ts';
    import Dialog from 'primevue/dialog';

    const props = defineProps<{
        dialogVisible: boolean;
        currentCompanyId?: string;
    }>();

    const emits = defineEmits<{
        companySelected: [string];
        dialogCanceled: [];
    }>();

    const authenticationStore = useAuthenticationStore();
    const { t } = useI18n();

    const selectedCompanyId = ref('');
    const assignedCompanies = computed(() => authenticationStore.assignedCompanies);

    watch(
        () => props.currentCompanyId,
        () => {
            selectedCompanyId.value = props.currentCompanyId ?? '';
        },
        { immediate: true }
    );

    function companySelected(): void {
        emits('companySelected', selectedCompanyId.value);
    }

    function selectCompanyId(companyId: string): void {
        selectedCompanyId.value = companyId;
    }
</script>

<template>
    <Dialog
        :visible="dialogVisible"
        modal
        :block-scroll="true"
        :header="
            props.currentCompanyId ? t('LOGIN.COMPLETE_LOGIN.HEADER_SWITCH') : t('LOGIN.COMPLETE_LOGIN.HEADER_SELECT')
        "
        class="login-dialog"
        :style="{ width: '40rem', 'max-width': '100%' }"
        :pt="{
            content: { style: 'padding: 0;' },
        }"
        @update:visible="emits('dialogCanceled')"
    >
        <div class="selection-container">
            <template v-for="company in assignedCompanies" :key="company.id">
                <RadioCard
                    :name="company.id"
                    class="company-selection"
                    :model-value="selectedCompanyId"
                    :value="company.id"
                    @update:model-value="selectCompanyId($event)"
                >
                    <template #header>
                        <div v-if="company.externalId">{{ company?.externalId }} - {{ company.companyName }}</div>
                        <div v-else>{{ company.companyName }}</div>
                        <div>
                            {{ company.companyAddress.countryCode.toUpperCase() }} -
                            {{ company.companyAddress.postalCode }}
                            {{ company.companyAddress.city }}
                        </div>
                    </template>
                </RadioCard>
            </template>
        </div>
        <template #footer>
            <div class="actions">
                <Button :label="t('COMMON.CANCEL')" outlined type="button" autofocus @click="emits('dialogCanceled')" />
                <Button
                    :disabled="selectedCompanyId === ''"
                    type="button"
                    :label="t('LOGIN.COMPLETE_LOGIN.COMPLETE')"
                    @click="companySelected()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .selection-container {
        margin-left: main.$spacing-6;
        margin-right: main.$spacing-6;
    }

    .company-selection {
        &:first-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    .actions {
        display: flex;
        justify-content: end;
        gap: 1ch;

        // Keep spacing consistent with the dialog
        padding-top: 20px;
    }
</style>
